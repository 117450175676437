<template>
  <div>
    <v-card>
      <v-card-text>
        <h3 class="headline">ເພີ່ມຂ່າວສານ Promotion</h3>
        <v-container>
          <v-form ref="form" lazy-validation>
            <v-row>
              <v-col>
                <div class="field">
                  <div class="file is-large is-boxed">
                    <label class="file-label">
                      <input
                          @change="onFileChange"
                          class="file-input input-file-image"
                          type="file"
                          name="image"
                          accept="image/*"
                          ref="image"
                          hidden
                      />
                      <span class="file-cta">
                            <span class="file-icon">
                              <v-icon
                                  style="
                                  font-size: 60px !important;
                                  color: #719aff;
                                  cursor: pointer;
                                "
                                  class="fas fa-cloud-upload"
                              >mdi-cloud-upload</v-icon
                              >
                            </span>
                            <span
                                class="file-label"
                                style="
                                margin-top: 10px;
                                text-transform: uppercase;
                                padding-top: 20px;
                              "
                            >
                              Choose Profile
                            </span>
                          </span>
                    </label>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <p class="errors">
                  {{ server_errors.imageFile }}
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="mt-5" v-if="image">
                <v-avatar class="avatar rounded" size="94px">
                  <img :src="imageUrl" alt=""/>
                </v-avatar>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="Title *"
                    v-model="news.title"
                    :rules="nameRules"
                    outlined
                    dense
                ></v-text-field>
                <p class="error--text">{{ server_errors.title }}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="Promotion Code"
                    v-model="news.promotion_code"
                    outlined
                    dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div class="control">
                  <vue-editor v-model="news.description"/>
                </div>
                <p class="errors">
                  {{ server_errors.description }}
                </p>
                <p class="error--text">{{ server_errors.description }}</p>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="Close()">
            Close
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              :loading="loading"
              :disabled="loading"
              @click="AddData()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import manage from "@/mixins/platform/manage_news_promotion";

export default {
  mixins: [manage],
};
</script>

<style>
.primary-color {
  color: #00c1d2;
}

@import "../../../../public/scss/main.scss";
</style>